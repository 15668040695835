import React, { Fragment } from 'react';
import { navigate } from "gatsby";
import Media from 'react-media';
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {window} from 'browser-monads';
import { getMachineTitle } from '../../utils/selectors';

import {
  DropdownWrapper
} from '../hero-heading-sub-nav/styles';

import {
  MachineHeading,
  SubNavigation,
  SubNavigationItem,
  HeadingContainer,
  Heading,
  ColourHeading,
  Dropdown,
  Option,
  MachineImageContainer,
  MachineImage,
  ColourOptionsContainer,
  ColourOption,
  BackgroundImage,
  BackgroundImageContainer,
  ListWrapper,
} from './styles';
import { isWide } from '../../utils/responsive';

class MachineHeroHeading extends React.Component {
  header;

  constructor(props) {
    super(props);

    this.state = {
      colourIndex: 0,
      subNavIndex: this.props.selectedSubNavigationIndex,
    };

    this.onSubNavigationChange = this.onSubNavigationChange.bind(this);
    this.onColourChange = this.onColourChange.bind(this);
  }

  componentDidMount() {
		if(typeof document !== 'undefined') {
			this.header = document.getElementsByTagName('header')[0];
			this.header.classList.add('absolute');
		}

		const { colourData } = this.props;

		if(colourData.length <= 1) return null;

		colourData.map(item => {
			if(item.background_image && item.background_image.url) {
				let image = new Image();
				image.src = item.background_image.url;
				return image;
			}
		});

		const hash = location.hash;
		if(hash && hash.indexOf('accessories') >= 0){
			// console.log("MachineHeroHeading -> componentDidMount -> hash", hash, hash.indexOf('accessories'))

			this.onTertiaryNavigationChange({
				currentTarget: {
					value: 3
				}
			})
		}
  }

  componentWillUnmount() {
    if(this.header) {
      this.header.classList.remove('absolute');
    }
  }

  onSubNavigationChange = (evt) => {
//   console.log(`🚀 ~ file: machineHeading.jsx ~ line 83 ~ evt`, evt);
    const {
      onSecondaryChange
    } = this.props;

    this.setState({
      subNavIndex: parseInt(evt.currentTarget.value, 10),
    });

    onSecondaryChange(parseInt(evt.currentTarget.value, 10));
  };

  onTertiaryNavigationChange = (evt) => {
    // console.log("MachineHeroHeading -> onTertiaryNavigationChange -> evt", evt.currentTarget.value)
    const { onTertiaryChange } = this.props

    // scroll.scrollTo(window.innerHeight + 100);
    const elmnt = document.getElementById('machineContent')
    // console.log(`🚀 ~ file: machineHeading.jsx ~ line 101 ~ elmnt`, elmnt)
    elmnt && elmnt.scrollIntoView({ behavior: 'smooth' })
    const toItem = parseInt(evt.currentTarget.value, 10)
    // console.log(`🚀 ~ file: machineHeading.jsx ~ line 104 ~ toItem`, toItem)
    onTertiaryChange(toItem)
  };

  onColourChange = (evt) => {
    const newIndex = evt.currentTarget.value;

    this.props.onColourChange(newIndex);

    this.setState({
      colourIndex: parseInt(newIndex, 10)
    });
  };

  renderSubNavigation = () => {
    const {
      subNavigationData,
      selectedSubNavigationIndex
	} = this.props;
	// console.log(subNavigationData);

    return (
      <SubNavigation>
        { subNavigationData.map((item, index) => (
          <SubNavigationItem
            active = { selectedSubNavigationIndex === index }
            onClick = { this.onSubNavigationChange }
            value = { index }
            key = { `nav${index}` }
          >
            { item.name }
          </SubNavigationItem>
)) }
      </SubNavigation>
    )
  };

	renderTertiaryNavigation = () => {
		const {
			useBlack,
			tertiaryNavigation,
			selectedTertiaryNavigationIndex,
		} = this.props;

		return (
			<Fragment>
				<Media
				query = "(min-width:768px)"
				render = { () => this.renderTertiaryNavigationList() }
				/>
				<div>
				<Media
					query = "(max-width:767px)"
					render = { () => (
					<DropdownWrapper isBlack = { useBlack }>
						<Dropdown onChange = { this.onTertiaryNavigationChange } value = { selectedTertiaryNavigationIndex }>
						{ tertiaryNavigation.map((link, index) => <Option key = { link.name.toLowerCase() } value = { index }>{ link.name }</Option>) }
						</Dropdown>
					</DropdownWrapper>
					) }
				/>
				</div>
			</Fragment>
		)
	};

  renderTertiaryNavigationList = () => {
		const {
		useBlack,
		tertiaryNavigation,
		selectedTertiaryNavigationIndex,
		} = this.props;

		return (
		<ListWrapper>
			<SubNavigation>
			{ tertiaryNavigation.map((item, index) => (
				<SubNavigationItem
				active = { selectedTertiaryNavigationIndex === index }
				onClick = { this.onTertiaryNavigationChange }
				value = { index }
				key = { `tert${index}` }
				>
				{ item.name }
				</SubNavigationItem>
			)) }
			</SubNavigation>
		</ListWrapper>
		)
  };

  renderHeading = () => {
    const {
      machine,
      subNavigationData,
      colourData,
      selectedSubNavigationIndex,
    } = this.props;

    const {
      subNavIndex,
      colourIndex
    } = this.state;

    const machineName = getMachineTitle(machine, subNavigationData, selectedSubNavigationIndex);
    const colourName = colourData.length <= 1 ? "" : colourData[colourIndex].colour_name.text;

    return (
      <HeadingContainer>
        <Heading>{ machineName }</Heading>
        {/* <ColourHeading>{ colourName }</ColourHeading> */}
      </HeadingContainer>
    )
  };

  renderHeroImage = (useLargeHeading) => {
    const {
      colourData,
      subNavigationData,
      selectedSubNavigationIndex
    } = this.props;

    const {
      colourIndex,
    } = this.state;

    const headingImage = colourData.length > 0 ? colourData[colourIndex].machine_image.url : subNavigationData[selectedSubNavigationIndex].image;
    const desktopHeadingImage = colourData.length > 0 ? colourData[colourIndex].desktop_machine_image.url : subNavigationData[selectedSubNavigationIndex].image;
    // const headingImage = subNavigationData[selectedSubNavigationIndex].image;
    // const desktopHeadingImage = subNavigationData[selectedSubNavigationIndex].image;
	// console.log('subNavigationData :>> ', subNavigationData);

    return (
      <div>
        <Media
          query = "(min-width:768px)"
          render = { () => <MachineImage useLargeHeader = { useLargeHeading } src = { desktopHeadingImage } /> }
        />
        <Media
          query = "(max-width:767px)"
          render = { () => <MachineImage useLargeHeader = { useLargeHeading } src = { headingImage } /> }
        />
      </div>
    )
  };

  renderColourSelect = () => {
    const {
      colourData
    } = this.props;

    if(colourData.length <= 1) return null;

    const {
      colourIndex,
    } = this.state;

    return (
      <ColourOptionsContainer>
        { colourData.map((color, index) => (
          <ColourOption
            onClick = { this.onColourChange }
            value = { index }
            colourIndex = { colourIndex }
            active = { colourIndex === index }
            key = { color.colour_name.text }
          >
            <img src = { color.icon_image.url } alt={color.colour_name.text} />
            { isWide && <span>{ color.colour_name.text }</span> }
          </ColourOption>
          ))
        }
      </ColourOptionsContainer>
    )
  };

  render = () => {
    const {
      useBlack,
      machine,
      subNavigationData,
      colourData,
      selectedSubNavigationIndex,
    } = this.props;

    const {
      colourIndex,
      subNavIndex
    } = this.state;

    const overallTitle = machine.machine_name.text;
    const machineName = getMachineTitle(machine, subNavigationData, subNavIndex);
    const useLargeHeader = machineName === 'Shotmaster';
    const extraPadding = overallTitle === "e2" || (isWide && (overallTitle === "e6" || overallTitle === "e4" || overallTitle === "e2"));
    const background = colourData &&  colourData[colourIndex] &&  colourData[colourIndex] && colourData[colourIndex].background_image ? colourData[colourIndex].background_image : null;
    const desktopBackground = colourData && colourData[colourIndex] && colourData[colourIndex].desktop_background_image ? colourData[colourIndex].desktop_background_image : null;

    return (
      <MachineHeading isBlack = { useBlack } useLargeHeader = { useLargeHeader } extraPadding = { extraPadding } overallTitle = { overallTitle }>
        { this.renderSubNavigation() }
        { this.renderTertiaryNavigation() }
        { this.renderHeroImage(useLargeHeader) }
        { this.renderHeading() }
		{ this.renderColourSelect() }
        <BackgroundImageContainer>
          <Media
            query = "(min-width:768px)"
            render = { () => <BackgroundImage src = { desktopBackground && desktopBackground.url ? desktopBackground.url : "" } /> }
          />
          <Media
            query = "(max-width:767px)"
            render = { () => <BackgroundImage src = { background && background.url ? background.url : "" } /> }
          />
        </BackgroundImageContainer>
      </MachineHeading>
    )
  }
}

export default MachineHeroHeading;
