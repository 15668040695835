import React from 'styled-components';
import styled from 'styled-components';
import withReveal from 'react-reveal/withReveal';
import { Fade } from 'react-reveal';
import breakpoint from 'styled-components-breakpoint';

export const ContactFormContainer = styled.div`
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  position: sticky;
`;

export const Toggle = styled.button`
  position: absolute;
  right: 20px;
  bottom: 0;
  
  img {
    width: 150px;
  }
`;

export const FormContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background: #FFF;
  
  /* h1, p {
    color: #FFF;
  } */
  
  svg g {
    fill: #FFF !important;
  }
  
  ${breakpoint('tablet')`
    top: auto;
    left: auto;
    bottom: 20px;
    right: 20px;
    width: 450px;
    padding-bottom: 20px;
    box-shadow: 0px 0px 55px 2px rgba(0,0,0,0.22);
    overflow-y: hidden;
  `};
`;

export const InnerFormContainer = styled.div`
  margin-top: -29px;
  padding: 0 30px;
`;

export const TopContent = styled.div`
  padding: 30px;
  /* background: url("/images/mobile_form_header.jpg") no-repeat top center #FFF; */
  background-color: #014455;
  background-size: 130%;
`;

export const CloseButton = styled.button`
  width: 16px;
  float: right;
  margin: 20px;
  z-index: 100;
  position: relative;
  cursor: pointer;
`;

export const ContactTitle = styled.h1`
  margin-top: 0;
  font-family: "Brown-Pro-Light", AdobeBlank, serif;
  
  color: white;
  span {
	  color: white;
    font-family: "Brown-Pro-Bold", AdobeBlank, serif;
  }
`;

export const ContactParagraph = styled.p`
  color: white;
`;
