import React, { Fragment } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import 'whatwg-fetch';

import { connect } from 'react-redux';
import {
  FormContainer,
  FormRow,
  FormRow2,
  SubmitRow,
  TextInput,
  SelectInput,
  TextArea,
  Checkbox,
  DropdownWrapper,
  ErrorMessage,
  SuccessMessage,
} from './styles'

import Button from '../button/button';
import { us_states } from '../../utils/usstates';

class ContactFormUI extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			sent: false,
			error: false,
			countryError: false,
			checked: false,
			isUSA: false
		}

		// console.log(`🚀 ~ file: contactForm.jsx ~ line 86 ~ render ~ countryList`, props.countryList);
	}

  sendEmail = (evt) => {
		evt.preventDefault();

		const name = evt.target.name.value;
		const email = evt.target.email.value;
		const company = evt.target.company.value;
		const phone = evt.target.phone.value;
		const country = evt.target.country.value;
		const us_state = this.state.isUSA ? evt.target.us_state.value : '';
		const comments = evt.target.comments.value;
		const emailContact = this.props.countryList.filter(c => c.country_code.text.toLowerCase() === country.toLowerCase())[0].contact_email.text;
		
		let hasError = false;

		// if(!country || country.length < 2 || country == 'na') {
		// 	hasError = true;
		// 	this.setState({error: true, countryError: true});
		// 	return;
		// }

		if(	name.length <= 0 ||
			email.length <= 0 ||
			!this.state.checked ||
			!country || 
			country == 'na')
		{
				hasError = true;
				this.setState({error: true, countryError: false});
				return;
		}

		this.setState({error: false, countryError: false});

		const body = {
        	name,
			email,
			company,
			phone,
			country,
			comments,
			emailContact,
			us_state
		};

		// console.log(`🚀 ~ file: contactForm.jsx ~ line 54 ~ body`, body);

		let urlParameters = Object.entries(body).map(e => e.join('=')).join('&');
        // console.log(`🚀 ~ file: contactForm.jsx ~ line 64 ~ urlParameters`, urlParameters);

		window.fetch(`https://hooks.zapier.com/hooks/catch/7108363/bbpb1st/?${urlParameters}`, {
			method: 'GET',
			mode: 'cors',
		}).then(() => {
			this.setState({
				sent: true,
			})
		});

		window.dataLayer = [];
		window.dataLayer.push({'event': 'contact_form_submission'});
  
  }

  toggleChange = () => {
    const checked = !this.state.checked;
    this.setState({checked});
  }

  onCountryChange = (e) => {
	  console.log(e.target.value);
	  this.setState({isUSA: e.target.value === 'us'});
  }

  render() {
    const { sent, checked, error, countryError } = this.state;
    const { name, email, company, phone, country, comments, submit, countryList, selectedCountry, gdpr, error_message, success_message, small } = this.props;
    // console.log(`🚀 ~ file: contactForm.jsx ~ line 86 ~ render ~ countryList`, countryList);
    // console.log(`🚀 ~ file: contactForm.jsx ~ line 86 ~ render ~ selectedCountry`, selectedCountry);
    // console.log(small);

	// let error_message_label = countryError ? "Country error" : error_message;
   
    return (
      <FormContainer onSubmit = { this.sendEmail } action = "POST">
        <FormRow>
          <TextInput type = "text" name = "name" placeholder = { name } />
          <TextInput type = "text" name = "email" placeholder = { email } />
        </FormRow>
        <FormRow2>
          <TextInput type = "text" name = "company" placeholder = { company } />
          <TextInput type = "tel" name = "phone" placeholder = { phone } />
          <DropdownWrapper>
            {/* <SelectInput name = 'country' placeholder = { country } defaultValue = { selectedCountry.country_code.text.toLowerCase() }> */}
            <SelectInput name = 'country' placeholder = { country } defaultValue='na' onChange={(e)=>{this.onCountryChange(e)}}>
              { countryList.map(c =>
                <option key = { c.country_code.text.toLowerCase() } value = { c.country_code.text.toLowerCase() }>{ c.country.text }</option>
              )}
              <option key='other' value='other'>Other</option>
            </SelectInput>
          </DropdownWrapper>
          {this.state.isUSA && <DropdownWrapper style={{gridColumn: 'span 3'}}>
            {/* <SelectInput name = 'country' placeholder = { country } defaultValue = { selectedCountry.country_code.text.toLowerCase() }> */}
            <SelectInput name = 'us_state' defaultValue='na'>
			<option key='not_selected' value='na'>State</option>
              { us_states.map(s =>
                <option key={ s.code } value={ s.name }>{ s.name }</option>
              )}
            </SelectInput>
          </DropdownWrapper>}
        </FormRow2>
        
        <TextArea name = 'comments' placeholder = { comments } />
        {error && <ErrorMessage>{error_message}</ErrorMessage>}
        {sent && <SuccessMessage>{success_message}</SuccessMessage>}
        <SubmitRow>
          <div onClick={this.toggleChange}>
            <Checkbox small={small}>
              <input type="checkbox" checked={checked} onChange={this.toggleChange} />
              <div dangerouslySetInnerHTML={{__html: gdpr}}></div>
            </Checkbox>
          </div>
          
          { !sent && <Button type = 'submit' black text = { submit } /> }
        </SubmitRow>
        
      </FormContainer>
    )
  }
}

const mapStateToProps = state => ({
  selectedCountry: state.country,
  countryList: state.countryList,
});

const mapDispatchToProps = dispatch => ({});

const ConnectedContactFormUI = connect(mapStateToProps, mapDispatchToProps)(ContactFormUI);

const ContactForm = ({small}) => (
  <Fragment>
    <StaticQuery
      query = { query }
      render = { data => (
        <ConnectedContactFormUI
          name = { data.allPrismicContact.edges[0].node.data.contact_form_name_placeholder.text }
          email = { data.allPrismicContact.edges[0].node.data.contact_form_email_placeholder.text }
          company = { data.allPrismicContact.edges[0].node.data.contact_form_company_placeholder.text }
          phone = { data.allPrismicContact.edges[0].node.data.contact_form_phone_number_placeholder.text }
          country = { data.allPrismicContact.edges[0].node.data.contact_form_country_selector_placeholder.text }
          comments = { data.allPrismicContact.edges[0].node.data.contact_form_comments_placeholder.text }
          submit = { data.allPrismicContact.edges[0].node.data.contact_form_submit_label.text }
          gdpr = { data.allPrismicContact.edges[0].node.data.gdpr.html }
          small={small}
          error_message = { data.allPrismicContact.edges[0].node.data.error_message }
          success_message = { data.allPrismicContact.edges[0].node.data.success_message }
        />
      ) }
    />
  </Fragment>
);

const query = graphql`
    query ContactFormQuery {
        allPrismicContact {
            edges {
                node {
                    data {
                        contact_form_introduction {
                            text
                        }
                        contact_form_name_placeholder {
                            text
                        }
                        contact_form_email_placeholder {
                            text
                        }
                        contact_form_company_placeholder {
                            text
                        }
                        contact_form_phone_number_placeholder {
                            text
                        }
                        contact_form_country_selector_placeholder {
                            text
                        }
                        contact_form_comments_placeholder {
                            text
                        }
                        gdpr {
                          html
                        }
                        contact_form_submit_label {
                            text
                        }
                        error_message
                        success_message
                    }
                }
            }
        }
    }
`

export default ContactForm;
