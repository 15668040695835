import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import SVG from 'react-inlinesvg'
import { Fade } from 'react-reveal'
import window from 'window-or-global'

import Layout from '../layouts/layout'
import MachineHeading from '../components/machine-heading/machineHeading'
import DynamicContentContainer from '../utils/dynamicContentContainer'
import {
  getMachineTypeData,
  getMachineTypes,
  getMachineHeadingImages,
  getPageComponents,
  getMachineTypesCoreInformation,
  getMachineTitle,
} from '../utils/selectors'
import {
  ContactFormContainer,
  FormContainer,
  Toggle,
  InnerFormContainer,
  CloseButton,
  TopContent,
  ContactTitle,
  ContactParagraph
} from '../styles/machine'
import ContactForm from '../components/contact-form/contactForm'
import { isWide } from '../utils/responsive'

class Machine extends React.Component {
  tertiaryNavigation

  constructor(props) {
    super(props)
    // console.log(props.data);

    // const isCameo = props.data.prismicMachine.data.machine_name.text == 'Cameo'
    const isCameo =
      props.data.prismicMachine.data.cameo_accessories_workaround == true
    const labels = props.data.allPrismicMachineLabels.edges[0].node.data

    this.tertiaryNavigation = [
      {
        name: labels.key_features_label.text,
      },
      {
        name: labels.specifications_label.text,
      },
      {
        name: labels.gallery_label.text,
      },
    ]

    // if (isCameo) {
    //   this.tertiaryNavigation.push({ name: labels.accessories_label.text })
    // }

    const data = this.props.data.prismicMachine
    const search =
      window.location && window.location.hash
        ? window.location.hash.slice(1, window.location.hash.length)
        : ''

    const machineTypes = getMachineTypes(data.data)
    // console.log("Machine -> constructor -> machineTypes", machineTypes)

    let index = machineTypes.findIndex(
      (elem) => elem.machine_type.document[0].uid === search
    )

    index = index < 0 ? 0 : index

    const machineTypesInfo = getMachineTypesCoreInformation(machineTypes)
    const machineTypeData = getMachineTypeData(machineTypes[index])
    const headingImages = getMachineHeadingImages(data.data, machineTypeData)
    const selectedColor =
      headingImages.length > 1
        ? headingImages[0].colour_name.text.toLowerCase()
        : ''

    this.state = {
      showContact: false,
      selectedTypeIndex: index,
      selectedTertiaryIndex: 0,
      contactFormOpen: false,
      machineTypes,
      machineTypesInfo,
      machineTypeData,
      headingImages,
      selectedColor,
    }

    this.onSecondaryChange = this.onSecondaryChange.bind(this)
    this.onTertiaryChange = this.onTertiaryChange.bind(this)
  }

  updateContent() {
    const data = this.props.data.prismicMachine
    const search =
      window.location && window.location.hash
        ? window.location.hash.slice(1, window.location.hash.length)
        : ''

    const machineTypes = getMachineTypes(data.data)
    let index = machineTypes.findIndex(
      (elem) => elem.machine_type.document[0].uid === search
    )

    index = index < 0 ? 0 : index

    const machineTypesInfo = getMachineTypesCoreInformation(machineTypes)
    const machineTypeData = getMachineTypeData(machineTypes[index])
    const headingImages = getMachineHeadingImages(data.data, machineTypeData)
    const selectedColor =
      headingImages.length > 1
        ? headingImages[0].colour_name.text.toLowerCase()
        : ''

    this.setState({
      showContact: false,
      selectedTypeIndex: index,
      selectedTertiaryIndex: 0,
      contactFormOpen: false,
      machineTypes,
      machineTypesInfo,
      machineTypeData,
      headingImages,
      selectedColor,
    })
  }

  componentWillReceiveProps() {
    this.updateContent()
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined')
      window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount = () => {
    if (typeof window !== 'undefined')
      window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop
    const showContact = scrollTop > 200

    if (showContact !== this.state.showContact) {
      this.setState({
        showContact,
      })
    }
  }

  onSecondaryChange = (index) => {
    // console.log("Machine -> index", index)
    const { data } = this.props.data.prismicMachine
    const { machineTypes } = this.state

    const machineTypeData = getMachineTypeData(machineTypes[index])
    const headingImages = getMachineHeadingImages(data, machineTypeData)

    this.setState({
      headingImages,
      machineTypeData: machineTypeData,
      selectedTypeIndex: index,
      selectedTertiaryIndex: 0,
    })
  }

  onTertiaryChange = (index) => {
    // console.log("Machine -> index", index)
    this.setState({
      selectedTertiaryIndex: index,
    })
  }

  onColourChange = (selectedColourIndex) => {
    const { headingImages } = this.state

    const colour =
      headingImages[selectedColourIndex].colour_name.text.toLowerCase()

    this.setState({
      selectedColor: colour,
    })
  }

  renderContactFormMobile = () => {
    const { contactFormOpen } = this.state

    typeof document !== 'undefined' &&
      document.body.classList[contactFormOpen ? 'add' : 'remove']('noscroll')

    return this.renderContactFormDesktop()
  }

  renderContactFormDesktop = () => {
    const {
      contactFormOpen,
      showContact,
      machineTypeData,
      machineTypesInfo,
      selectedTypeIndex,
    } = this.state

    const labels = this.props.data.allPrismicContact.edges[0].node.data

    const machineName = getMachineTitle(
      this.props.data.prismicMachine.data,
      machineTypesInfo,
      selectedTypeIndex
    )

    return (
      <ContactFormContainer style={{ zIndex: contactFormOpen ? '1000' : '1' }}>
        {showContact && (
          <Toggle onClick={() => this.setState({ contactFormOpen: true })}>
            <Fade>
              <SVG src="/images/contact_button_mobile.svg" />
            </Fade>
          </Toggle>
        )}
        {contactFormOpen && (
          <FormContainer>
            <CloseButton
              onClick={() => this.setState({ contactFormOpen: false })}
            >
              <SVG src="/images/X.svg" />
            </CloseButton>
            <TopContent>
              <ContactTitle>
                {machineName === 'Cameo'
                  ? labels.contact_form_modal_heading_cameo.text
                  : labels.contact_form_modal_heading.text}
                <span> {machineName}</span>
              </ContactTitle>
              <ContactParagraph>
                {labels.contact_form_introduction.text}
              </ContactParagraph>
            </TopContent>
            <InnerFormContainer>
              <ContactForm small />
            </InnerFormContainer>
          </FormContainer>
        )}
      </ContactFormContainer>
    )
  }

  renderContactForm = () => {
    return isWide
      ? this.renderContactFormDesktop()
      : this.renderContactFormMobile()
  }

  render = () => {
    const {
      selectedTypeIndex,
      selectedTertiaryIndex,
      machineTypesInfo,
      machineTypeData,
      headingImages,
      selectedColor,
    } = this.state

    const { data, prismicId } = this.props.data.prismicMachine

    let index

    //console.log('Rendering with: ', this.props, this.state);

    /*
     * Lazy fix because we did have 4 tabs, but condensed down to 3.
     */
    // console.log('===========', selectedTertiaryIndex)
    if (selectedTertiaryIndex + 1 === 3) {
      index = 'body1'
    } else {
      index =
        selectedTertiaryIndex === 0
          ? 'body'
          : `body${selectedTertiaryIndex + 1}`
      if (selectedTertiaryIndex === 3) index = 'body3'
    }
    // console.log(machineTypeData)
    // console.log('===========', selectedTertiaryIndex, index)

    let components = getPageComponents(machineTypeData[`${index}`])
    // console.log(`🚀 ~ file: machine.jsx ~ line 289 ~ components`, components)

    if (index === 'body1' && selectedColor) {
      components = components.filter((comp) => {
        return comp.component.document[0].data.tag.text === selectedColor
      })
    }

    return (
      <Layout isWhite={machineTypesInfo[selectedTypeIndex].useBlack}>
        <MachineHeading
          machine={data}
          subNavigationData={machineTypesInfo}
          selectedSubNavigationIndex={selectedTypeIndex}
          selectedTertiaryNavigationIndex={selectedTertiaryIndex}
          colourData={headingImages}
          tertiaryNavigation={this.tertiaryNavigation}
          onSecondaryChange={this.onSecondaryChange}
          onTertiaryChange={this.onTertiaryChange}
          onColourChange={this.onColourChange}
          useBlack={machineTypesInfo[selectedTypeIndex].useBlack}
        />
        <div id="machineContent">
          <DynamicContentContainer
            name={`Machine ${prismicId}`}
            data={components}
          />
        </div>
        {this.renderContactForm()}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query GetMachine($uid: String!) {
    prismicMachine(uid: { eq: $uid }) {
      uid
      data {
        cameo_accessories_workaround
        machine_name {
          text
        }
        body2 {
          items {
            background_image {
              url
            }
            machine_image {
              url
            }
            desktop_machine_image {
              url
            }
            desktop_machine_background {
              url
            }
            colour_name {
              text
            }
            icon_image {
              url
            }
          }
        }
        body1 {
          items {
            machine_type {
              document {
                __typename
                ...MachineType
              }
            }
          }
        }
      }
    }

    allPrismicMachineLabels {
      edges {
        node {
          data {
            key_features_label {
              text
            }
            specifications_label {
              text
            }
            accessories_label {
              text
            }
            gallery_label {
              text
            }
          }
        }
      }
    }

    allPrismicContact {
      edges {
        node {
          data {
            contact_form_introduction {
              text
            }
            contact_form_modal_heading_cameo {
              text
            }
            contact_form_modal_heading {
              text
            }
          }
        }
      }
    }
  }
`

export default Machine
